import React from "react"
import { User } from "firebase/auth"
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";

interface MenuOverlayProps{
    isMenuOpen: boolean,
    currentUser: User | null,
    closeMenu: () => any,
    setSigningOut: () => any,
    navigateSignIn: (screen: string) => any,
    navigateSignUp: (screen: string) => any,
}

const MenuOverlay = ({isMenuOpen, currentUser, closeMenu, setSigningOut, navigateSignIn, navigateSignUp}: MenuOverlayProps) => {

    const navigate = useNavigate()

    const { pathname } = useLocation()

    const returnedNavigation = (path: string, navigate: any) => {
        if (pathname == path)
            closeMenu()
        else
            navigate
    }

    return (
        <div className={`${isMenuOpen ? "opacity-100 z-40 -translate-x-0" : "opacity-0 -z-10 translate-x-full"} duration-200 fixed top-0 left-0 w-full h-full bg-blue_sky z-40`}> {/* Inner navigation menu */}
            <nav className="flex flex-col h-full items-center text-center justify-center relative">
                { currentUser?.uid ? (
                    <div className="flex flex-col justify-between w-full">
                        <a onClick={() => returnedNavigation("/add-prayer", navigate("/add-prayer"))}><p className="py-6 w-full text-lg font-medium text-primary_color_10">{`AJOUTER UNE JANAZA`}</p></a>
                        <a onClick={() => returnedNavigation("/prayers", navigate("/prayers"))}><p className="py-6 w-full text-lg font-medium text-primary_color_10">{`VOIR LES JANAZA`}</p></a>
                        <a onClick={setSigningOut}><p className="py-6 w-full text-lg font-medium text-red">{`SE DECONNECTER`}</p></a>
                    </div>
                ) : (
                    <>
                        <a onClick={() => returnedNavigation("/signin", navigateSignIn("/signin"))}><p className="py-4 w-full text-xl font-medium text-primary_color_10">Se connecter</p></a>
                        <a onClick={() => returnedNavigation("/signup", navigateSignUp("/signup"))}><p className="py-4 w-full text-xl font-medium text-primary_color_10">{`S'inscrire`}</p></a>
                    </>
                )}
                <div className='absolute bottom-10 flex flex-row gap-5'>
                    <a href={"https://twitter.com/janaza_app"} className='rounded-full flex justify-center items-center size-16 bg-primary_color bg-opacity-80 transition-all hover:bg-opacity-35'>
                        <Icon icon="pajamas:twitter" color='white' fontSize={24} className=''/>
                    </a>
                    <a href={"https://www.instagram.com/janaza_app"} className='rounded-full flex justify-center items-center size-16 bg-primary_color bg-opacity-80 transition-all hover:bg-opacity-35'>
                        <Icon icon="mdi:instagram" color='white' fontSize={26}/>
                    </a>
                    <a href={"https://play.google.com/store/apps/details?id=com.cdm.janaza"} className='rounded-full flex justify-center items-center size-16 bg-primary_color bg-opacity-80 transition-all hover:bg-opacity-35'>
                        <Icon icon="bxl:play-store" color='white' fontSize={28}/>
                    </a>
                </div>
            </nav>
            
      </div>
    )
};

export default MenuOverlay;