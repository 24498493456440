function extractDateInfo(prayerDate: string): string {
    const prayerDateObj = new Date(prayerDate);

    const day = prayerDateObj.getDate();
    const month = prayerDateObj.toLocaleString("fr-FR", { month: "long" });
    const year = prayerDateObj.getFullYear();

    return `${day} ${month} ${year}`;
}

export default extractDateInfo;