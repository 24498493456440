import React, { useEffect, useRef } from "react"
import DropDown from "./DropDown"
import useOnclickOutside from "react-cool-onclickoutside";

interface MenuDropDownProps {
    noSpaceAvailable?: boolean,
    className: string,
    placeholder: string,
    query: string,
    bgColor?: string,
    borderColor?: string,
    valueList: string[]
    isDropDownClicked: boolean,
    setShowDropDown: (value: boolean) => any,
    valueSelection: (value: string) => any,
}

const MenuDropDown = ({noSpaceAvailable, className, placeholder, query, bgColor, borderColor, valueList, isDropDownClicked, setShowDropDown, valueSelection}: MenuDropDownProps) => {

    const toggleDropDown = () => {
        setShowDropDown(!isDropDownClicked);
    };

    const mapRef = useOnclickOutside(() => {
        setShowDropDown(false);
    });
    
    return (
        <div ref={mapRef} tabIndex={0} className={`${className} relative`}>
            { isDropDownClicked && noSpaceAvailable && (
                <DropDown
                    className={`absolute bottom-[52px] z-10 bg-grey_30 max-w-lg w-full py-2 rounded-xl shadow-[0px_-11px_28px_0px_#00000024] max-h-56 overflow-x-hidden overflow-scroll`}
                    values={valueList}
                    showDropDown={false}
                    toggleDropDown={toggleDropDown}
                    valueSelection={(value: string) => valueSelection(value)}
                />
            )}
            <input
                required
                name=""
                type="text"
                readOnly
                placeholder={placeholder}
                onClick={toggleDropDown}
                value={query}
                className={`${query != "" ? "border-primary_color" : `${borderColor ? borderColor : "border-grey_40"}`} focus:outline-none focus:border-primary_color placeholder:text-grey_light text-primary_color_10 cursor-pointer text-grey_light border-2 text-base w-full py-3 box-border ${bgColor ? bgColor : "bg-grey_30"} px-5 caret-transparent transition-colors rounded-lg`}
            />
            { isDropDownClicked && !noSpaceAvailable && (
                <DropDown
                    className="absolute z-10 bg-grey_30 max-w-lg w-full py-2 rounded-xl shadow-lg max-h-56 overflow-x-hidden overflow-scroll"
                    values={valueList}
                    showDropDown={false}
                    toggleDropDown={toggleDropDown}
                    valueSelection={(value: string) => valueSelection(value)}
                />
            )}
        </div>
    )
}

export default MenuDropDown;

