import React from "react";

interface ChipActionProps {
    text: string,
    icon: any,
    className: string,
    onClick: (event: any) => any,
}

const ChipAction = ({text, icon, className, onClick}: ChipActionProps) => {
    return (
        <div onClick={(event: any) => onClick(event)} className={`${className} items-center flex flex-row h-fit w-fit py-1 px-3 bg-primary_color_90 rounded-md`}>
            {icon}
            <p className="text-sm font-normal text-primary_color_10">{text}</p>
        </div>
    )
};

export default ChipAction;