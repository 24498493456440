import React from "react";

interface PhoneWithBackgroundProps {
    url: string,
    color: string,
    className?: string
}

const PhoneWithBackground = ({ url, color, className }: PhoneWithBackgroundProps) => {
    return (
        <div className={`${className} relative max-w-md`}>
            <div className={`aspect-[6/5] ${color} rounded-2xl`}/>
            <img src={url} alt="" className={`translate-x-[-50%] translate-y-[-50%] w-2/3 top-1/2 absolute left-1/2`}/>
        </div>
        
    )
}

export default PhoneWithBackground;