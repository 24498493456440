import React from "react";

interface SecondaryTitleProps {
    text: string,
    className?: string
}

const SecondaryTitle = ({ text, className }: SecondaryTitleProps) => {
    return (
        <h2 className={`${className} text-primary_color_10 font-semibold text-3xl lg:text-4xl`}>{text}</h2>
    )
}

export default SecondaryTitle;