export const lowercaseStringBuilderList = (
    text: string
) => {
    const separatedName = text.trim().split(" ");

    const list: string[] = [];

    separatedName.forEach((name) => {
        list.push(unnacented(name.toLowerCase()))
    })

    return list
}

// export function lowercaseStringBuilderList(text: string): Array<string> {
//     let separatedName = text.trim().split(" ");

//     let list: string[] = [];

//     separatedName.forEach((name) => {
//         list.push(unnacented(name.toLowerCase()))
//     })

//     return list
// }

export function lowercaseStringBuilderText(text: string): string {
    return unnacented(text.trim().toLocaleLowerCase())
}

function unnacented(text: string): string {
    const temp = text.normalize("NFD");
    const regexUnnacent = /[\u0300-\u036f]/g;

    return temp.replace(regexUnnacent, "");
}