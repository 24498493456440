/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { auth, signOutUser, userStateListener } from "../data/repository/firebaseAuth";
import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";

interface ContextProps {
    children?: ReactNode
}

export const AuthContext = createContext({
    currentUser: {} as User | null,
    setCurrentUser: (_user: User) => {},
    signOut: async () => {}
});

export const AuthContextProvider = ({children}: ContextProps) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const navigate = useNavigate()

    // userState/

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user)
            } else {
                setCurrentUser(null)
            }
        });
        return () => { unsubscribe(); };
    }, []);

    const signOut = async () => {
        await signOutUser()
        setCurrentUser(null)
        navigate("/")
    }

    const value = {
        currentUser,
        setCurrentUser,
        signOut,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}