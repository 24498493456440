import React from "react";

interface FormFieldsContainerProps {
    children: React.ReactNode,
    className: string,
    label: string
}

const FormFieldsContainer = ({children, className, label}: FormFieldsContainerProps) => {
    return (
        <div className={`${className} flex flex-col`}>
            <label className="font-medium text-xl text-primary_color mb-2">{label}</label>
            <div className="rounded-xl bg-grey_30 p-4">
                <>{children}</>
            </div>
        </div>
    )
}

export default FormFieldsContainer;