import React, { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, BrowserRouterProps, Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./presentation/pages/Home"
import SignInPage from "./presentation/pages/SignIn"
import SignUpPage from "./presentation/pages/SignUp"
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./config/config";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import Prayers from "./presentation/pages/Prayers";
import AddPrayer from "./presentation/pages/AddPrayer";
import { Sign } from "crypto";
import { auth } from "./data/repository/firebaseAuth"
import Loading from "./components/commons/Loading";
import AuthPages from "./components/AuthPage";
import ScrollToTop from "./utils/scrollToTop";

// export const app = initializeApp(firebaseConfig);

// export interface IApplicationProps {
//     children: React.ReactNode
// }

const Application: React.FunctionComponent = (props) => {
    const { currentUser } = useContext(AuthContext)
    const [isUserLoading, setUserLoading] = useState(true)

    useEffect(() => {
        const waitForAuth = async () => {
            await auth.authStateReady()
            setUserLoading(false)
        }
        waitForAuth()
    }, [currentUser])

    return (
        <>
            <BrowserRouter>
                <AuthContextProvider>
                    <ScrollToTop/>
                    <Routes>
                        <Route
                            index
                            path="/"
                            element={
                                // <AuthPages>
                                    <HomePage />
                                // </AuthPages>
                            }
                        />
                        <Route path="/signin" element={<SignInPage/>} />
                        <Route path="/signup" element={<SignUpPage/>} />
                        <Route path="/prayers" element={<Prayers/>} />
                        <Route
                            path="/add-prayer"
                            element={
                                <AuthPages>
                                    <AddPrayer/>
                                </AuthPages>
                            }
                        />
                    </Routes>
                </AuthContextProvider>
            </BrowserRouter>
        </>
    );
};

export default Application;


// const primaryPrayerPath = "pray";

// const devPath = "dev_pray";

// const messaging = admin.messaging();

// export const notificationWhenNewPrayerCreated = functions.firestore.document(primaryPrayerPath + "/{documentId}")
//     .onCreate(async (snap, context) => {
//         const address: String = snap.data()["address"];
//         const name: String = snap.data()["name"];
//         const date: String = snap.data()["date"];
//         const isUpdated: Boolean = snap.data()["is_updated"];
//         const payload = {
//             notification: {
//                 title: "Nouvelle Janaza !",
//                 body: `Salat Janaza de ${name} à cette adresse : ${address}, le ${date}.`
//             },
//             android: {
//                 notification: {
//                     channelId: "FCM_JANAZA_CHANNEL"
//                 }
//             },
//             topic: notificationTopic,
//         };
//         if (!isUpdated) {
//             messaging.send(payload)
//                 .then((response) => {
//                     console.log("Successfully sent message:", response);
//                 })
//                 .catch((error) => {
//                     console.log("Error sending message:", error);
//                 });
//         }
        
// });

// export const notificationWhenNewPrayerCreatedDev = functions.firestore.document(devPath + "/{documentId}")
//     .onCreate(async (snap, context) => {
//         const address = snap.data()["address"];
//         const isUpdated: Boolean = snap.data()["is_updated"];
//         const payload = {
//             notification: {
//                 title: "Test : Nouvelle Janaza !",
//                 body: "Test : Une Salat Janaza a été ajouté à cette adresse : " + address,
//             },
//             topic: notificationTopicDev,
//         };
//         const payloadUpdate = {
//             notification: {
//                 title: "Test : Janaza mise à jour !",
//                 body: "Test : La Janaza a bien ete mise à jour. : ",
//             },
//             topic: notificationTopicDev,
//         };

//         if (!isUpdated) {
//             messaging.send(payload)
//                 .then((response) => {
//                     console.log("Successfully sent message:", response);
//                 })
//                 .catch((error) => {
//                     console.log("Error sending message:", error);
//                 });
//         } else {
//             messaging.send(payloadUpdate)
//                 .then((response) => {
//                     console.log("Successfully sent update:", response);
//                 })
//                 .catch((error) => {
//                     console.log("Error sending update:", error);
//                 });
//         }
// });

// const notificationTopic = "Janaza";
// const notificationTopicDev = "JanazaDevNotification";