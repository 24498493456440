/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';

type DropDownProps = {
    values: string[],
    showDropDown: boolean,
    toggleDropDown: Function,
    valueSelection: Function,
    className: string
};

const DropDown: React.FC<DropDownProps> = ({
    className,
    values,
    toggleDropDown,
    valueSelection,
}: DropDownProps): JSX.Element => {
    const [showDropDown, setShowDropDown] = useState(false);

    const onClickHandler = (value: string): void => {
        toggleDropDown()
        valueSelection(value);
    };

    useEffect(() => {
        setShowDropDown(showDropDown);
    }, [showDropDown]);

    return (
        <>
            <div className={`${className}`}>
                { values.map(
                    (value: string, index: number): JSX.Element => {
                        return (
                            <p
                                className='text-base cursor-pointer hover:bg-blue_sky font-regular px-5 py-3'
                                key={index}
                                onClick={(): void => { 
                                    onClickHandler(value);
                                }}
                            >
                                {value}
                            </p>
                        );
                    }
                )}
            </div>
        </>
    );
};

export default DropDown;
