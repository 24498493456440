import { GeoPoint } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

interface GoogleAutoCompleteDropDownProps {
    noSpaceAvailable?: boolean,
    reset: boolean
    className: string,
    placeholder: string,
    onHandleSelectAddress: (address: string, geopoint: GeoPoint) => any,
}

const GoogleAutoCompleteDropDown = ({noSpaceAvailable, reset, className, placeholder, onHandleSelectAddress}:GoogleAutoCompleteDropDownProps ) => {

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "",
        debounce: 100,
    });

    const [tempSelectedAddress, setTempSelectedAddress] = useState("");
    const [borderColor, setBorderColor] = useState('border-grey_40')

    const mapRef = useOnclickOutside(() => {
        clearSuggestions();
    });

    const handleInputAddress = (e: any) => {
        setValue(e.target.value);
    };
    
    const handleSelectAddress = async (address: string) => {
        setValue(address, false);
        clearSuggestions()
    
        const results = await getGeocode({ address });
        const {lat, lng} = await getLatLng(results[0]);
    
        const geopoint = new GeoPoint(lat, lng);

        setTempSelectedAddress(address)
        onHandleSelectAddress(address, geopoint);
    };

    useEffect(() => {
        setTempSelectedAddress('')
        setValue('')
    }, [reset])

    useEffect(() => {
        if (value === '')
            setBorderColor("border-grey_40")
        else if (value != tempSelectedAddress)
            setBorderColor("border-red")
        else
            setBorderColor("border-primary_color")
    }, [value, tempSelectedAddress])
    

    return (
        <div className={`${className} relative`} ref={mapRef}>
            { status === "OK" && noSpaceAvailable &&
                <div className={`absolute bottom-[52px] z-10 bg-grey_30 max-w-lg w-full py-2 rounded-xl shadow-lg max-h-56 overflow-x-hidden overflow-scroll`}>
                    { data.map(({ place_id, description }) => (
                        <p
                            className='text-base cursor-pointer hover:bg-blue_sky font-regular px-5 py-3'
                            key={place_id}
                            onClick={(): void => { 
                                handleSelectAddress(description);
                            }}
                        >
                            {description}
                        </p>
                    ))}
                </div>
            }

            <input
                required
                placeholder={placeholder}
                type="text"
                name="address"
                value={value}
                onChange={handleInputAddress}
                disabled={!ready}
                className={`${borderColor} text-base w-full py-3 box-border placeholder-grey_light bg-grey_30 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
            />

            { status === "OK" && !noSpaceAvailable &&
                <div className={`absolute z-10 bg-grey_30 max-w-lg w-full py-2 rounded-xl shadow-lg max-h-56 overflow-x-hidden overflow-scroll`}>
                    { data.map(({ place_id, description }) => (
                        <p
                            className='text-base cursor-pointer hover:bg-blue_sky font-regular px-5 py-3'
                            key={place_id}
                            onClick={(): void => { 
                                handleSelectAddress(description);
                            }}
                        >
                            {description}
                        </p>
                    ))}
                </div>
            }
            { tempSelectedAddress &&
                <p className="text-sm ps-3 pt-1 text-grey_light">{`Lieu selectionné : ${tempSelectedAddress}`}</p>
            }
        </div>    
    )
}

export default GoogleAutoCompleteDropDown;