import React from "react";

interface BorderButtonProps {
    text: string,
    small: boolean,
    type?: "submit" | "reset" | "button" | undefined,
    className?: string,
    isDisabled?: boolean,
    onClick?: () => void,
}

const BorderButton = ({ text, small, type, className, isDisabled, onClick }: BorderButtonProps) => {
    return (
        <button type={type} disabled={isDisabled} className={`${className} rounded-full bg-transparent h-auto text-primary_color_10 border-primary_color_10 border-2 hover:bg-black hover:bg-opacity-5 transition-colors`} onClick={onClick}>
            <p className={`font-medium text-base px-4 ${small ? "py-2" : "py-3"} lg:text-base`}>{text}</p>
        </button>
    )
}

export default BorderButton;