import React, { useRef } from 'react'
import { useContext, useEffect, useState } from "react"
import { PrayerFromFirebase as PrayerModel } from "../../models/prayer";
import { useNavigate } from "react-router-dom";

import SecondaryTitle from "../../components/titles/SecondaryTitle";
import { AuthContext } from "../../context/AuthContext";
import { deletePrayer, listener_prayerDocs, onetime_prayerDocs, updateCountView } from "../../data/repository/firebasePrayers";
import MenuOverlay from "../../components/navigation/MenuOverlay";

import janazaMarker from "../../styles/drawable/marker_onboarding.png";
import { ArrowUpwardRounded, CheckRounded, CloseRounded, DirectionsCarRounded, EqualizerRounded, FilterAltRounded, FilterListRounded, ImageRounded, LinkRounded, LocationOnRounded, MapRounded, Newspaper, ViewListRounded, WcRounded } from "@mui/icons-material";

import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { onSnapshot } from "firebase/firestore";
import { toast } from "sonner";
import { useInView } from 'react-intersection-observer';

import { PrayerCardMobile } from "../../components/PrayerCard";
import FooterJanaza from "../../components/commons/FooterJanaza";
import HeaderMenu from "../../components/navigation/headerMenu/HeaderMenu";
import timeout from "../../utils/timeout";


import {
    APIProvider,
    Map,
    InfoWindow,
    Marker,
} from "@vis.gl/react-google-maps";
import ChipAction from '../../components/ChipAction';
import PlainButton from '../../components/buttons/PlainButton';
import formatPrayerDate from '../../utils/formatPrayerDate';
import extractDateInfo from '../../utils/extractDateinfo';

enum Mode {
    Map = 'Map',
    List = 'List'
}

interface GoogleMapProps {
    prayers: PrayerModel[],
    setSelectionModeVisibility: (value: boolean) => any
}

interface ChoiceModeButtonProps{
    selectedMode: Mode,
    selectedModeVisibility: boolean,
    setSelectedMode: (mode: Mode) => any
}

const Prayers: React.FunctionComponent = (props) => {

    const { currentUser, signOut } = useContext(AuthContext)

    const { ref, inView } = useInView({ threshold: 0.1 });

    const navigate = useNavigate();

    const markersRef = useRef<{ [key: string]: google.maps.Marker }>({});

    const scrollRef = useRef<HTMLDivElement | null>(null);

    const [viewCountPrayerId, setViewCountPrayerId] = useState<string[]>([]);

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [defaultPrayers, setDefaultPrayers] = useState<PrayerModel[]>([]);
    const [prayers, setPrayers] = useState<Record<string, PrayerModel[]>>({});
    const [realTimePrayers, setRealTimePrayers] = useState<PrayerModel[]>([]);

    const [isLoading, setLoading] = useState(true)
    const [selectedMode, setSelectedMode] = useState<Mode>(Mode.List)
    const [overlayVisible, setOverlayVisibility] = useState(false)

    const [filtersLocalisation, setFiltersLocalisation] = useState<Array<string | null>>([]);
    const [tempSelectedFilter, setTempSelectedFilter] = useState<string | null>(null)
    const [selectedlocalisationFilter, setSelectedlocalisationFilter] = useState<string | null>(null)

    const [selectedPrayer, setselectedPrayer] = useState<PrayerModel>()

    const [selectModeVisibility, setSelectModeVisibility] = useState<boolean>(true)

    const [openDialogFilter, setOpenDialogFilter] = useState<boolean>(false)

    const [filterBottomSheetVisibility, setFilterBottomSheetVisibility] = useState<boolean>(false)

    const onFilterBottomSheetChanged = (value: boolean) => {
        setSelectModeVisibility(!value)
        setFilterBottomSheetVisibility(value)
    }

    const onValidFilters = async () => {
        if (tempSelectedFilter == selectedlocalisationFilter) {
            onFilterBottomSheetChanged(false);
            return;
        }
        setOpenDialogFilter(false)
        onFilterBottomSheetChanged(false);
        // setLoading(true);

        // await timeout(200);
        setSelectedlocalisationFilter(tempSelectedFilter);

        if (tempSelectedFilter == null) {
            setPrayers(groupPrayersByDate(defaultPrayers));
        } else {
            setPrayers(
                groupPrayersByDate(defaultPrayers.filter((defaultPrayer) => defaultPrayer.administrativeArea1?.[0] === tempSelectedFilter))
            );
        }
        // await timeout(500);
        // setLoading(false);
    }

    const enlargeMarker = (id: string | undefined) => {
        if (id == undefined) return

        const marker = markersRef.current[id];

        if (marker) {
            marker.setZIndex(100)
        }
      };
    

      const resetMarker = (id: string | undefined) => {
        if (id == undefined) return

        const marker = markersRef.current[id];

        if (marker) {
            marker.setZIndex(undefined)
        }
      };

    const handleSignOut = async () => {
        setOverlayVisibility(true)
        try {
            await timeout(1000)
            await signOut()
            setOverlayVisibility(false)
            toast.error("Vous êtes désormais déconnecté.")
            setMenuOpen(false)
        } catch (error) {
            setOverlayVisibility(false)
            toast.error("Une erreur est survenue.")
            console.log(error)
        }
    }

    const fetchPrayersListener = async () => {
        setLoading(true);
        await timeout(300);

        // const prayersDocs = await listener_prayerDocs;

        const unsubscribe = onSnapshot(listener_prayerDocs, (snapshot) => {
            const newPrayers = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as PrayerModel));

            setRealTimePrayers(newPrayers);
            setFiltersLocalisation(
                [...new Set(
                    newPrayers
                        .map(doc => doc.administrativeArea1?.[0] ?? null)
                        .filter((area) => area !== null)
                    )
                ]
            )
            setLoading(false);

            if (defaultPrayers.length == 0) {
                setDefaultPrayers(newPrayers);
                setPrayers(groupPrayersByDate(newPrayers));
            }
        });

        return unsubscribe;
    }

    const setNewPrayers = () => {
        window.scrollTo(0, 0)

        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }

        setDefaultPrayers(realTimePrayers)

        const prayersGroupedByDate = groupPrayersByDate(realTimePrayers)

        if (selectedlocalisationFilter == null) {
            setPrayers(prayersGroupedByDate)
        }

        setFiltersLocalisation(
            [...new Set(
                realTimePrayers
                    .map(doc => doc.administrativeArea1?.[0] ?? null)
                    .filter((area) => area !== null)
                )
            ]
        )
    }

    const onDeletePrayer = async (prayerId: string) => {
        setOverlayVisibility(true)

        try {
            await timeout(1000)
            await deletePrayer(prayerId);
            setDefaultPrayers(defaultPrayers.filter(prayer => prayer.id != prayerId))
            if (selectedlocalisationFilter == null) {
                setPrayers(groupPrayersByDate(defaultPrayers.filter(prayer => prayer.id != prayerId)));
            } else {
                setPrayers(groupPrayersByDate(defaultPrayers.filter(prayer => prayer.id != prayerId).filter((defaultPrayer) => defaultPrayer.administrativeArea1?.[0] === selectedlocalisationFilter)));
            }
            toast.success("La Salat Janaza a bien été supprimée")
            setOverlayVisibility(false)
        } catch (error) {
            toast.error("Impossible de supprimer la Salat Janaza pour le moment")
            setOverlayVisibility(false);
        }
    }

    const onUpdateCountView = async (prayerId: string) => {
        const userId: string = currentUser != null ? currentUser.uid : "null";

        try {
            if (viewCountPrayerId.includes(prayerId))
                return;

            console.log("recomposotion")

            setViewCountPrayerId(prevViewCountPrayerId => [...prevViewCountPrayerId, prayerId]);
            
            updateCountView(prayerId, userId);
        } catch (error) {
            console.log("Erreur onUpdateCountView: " + error);
        }
    }

    useEffect(()=> {
        setSelectModeVisibility(!inView)
    }, [inView]);

    useEffect(() => { 
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isMenuOpen, overlayVisible]);

    useEffect(() => {
        fetchPrayersListener()
    }, []);

    return (
        <div className="bg-white w-full min-h-dvh lg:h-dvh font-roboto 2xl:m-auto relative">
            <section className={`${selectedMode === Mode.List ? "h-full" : "h-dvh"} flex flex-col w-full overflow-hidden items-center bg-blue_sky`}> {/* First Section */}
                <HeaderMenu
                    classNames=""
                    currentUser ={currentUser}
                    isBackButtonVisible={true}
                    isHamburgerVisible={true}
                    isMenuOpen={isMenuOpen}
                    isAddJanazaButtonVisible={true}
                    isInfoBarHeaderVisible={false}
                    horizontalPadding="px-4 lg:px-6 xl:px-6"
                    setMenuOpen={setMenuOpen}
                    handleSignOut={handleSignOut}
                />
                <div className="relative flex flex-col bg-transparent min-h-dvh pt-[74px] lg:pt-0 self-center w-full lg:px-0"> {/* Body first section */}
                    { isLoading ? (
                        <div className="flex z-50 justify-center pt-20 w-full">
                            <div className="flex flex-col h-full justify-center self-center">
                                <svg aria-hidden="true" className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 self-center fill-primary_color" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                            </div>
                        </div>
                    ) : (
                        <>
                            { selectedMode === Mode.List ? (
                                <div className='px-4 pt-5 pb-0 h-full w-full lg:pt-[74px] lg:h-dvh lg:px-0'>
                                    <div className="flex flex-row justify-between mb-2 w-full items-center lg:justify-between lg:hidden">
                                        <h2 className={`text-primary_color_10 font-medium text-xl`}>Les Janaza | <span className='text-primary_color'>Monde</span></h2>
                                        <div
                                            className={`${selectedlocalisationFilter != null ? "border-primary_color border-[1.5px]" : "border-primary_color_80 border-[1.5px]"} relative flex flex-row bg-primary_color_80 rounded-lg w-fit px-3 py-1 gap-2 lg:hidden`}
                                            onClick={() => onFilterBottomSheetChanged(true)}
                                        >
                                            <FilterListRounded className='text-primary_color'/>
                                            { selectedlocalisationFilter != null &&
                                                <div className=' flex absolute top-0 right-0 h-5 w-5 rounded-full self-center bg-primary_color translate-x-1/2 -translate-y-1/2'>
                                                    <p className='text-xs font-medium text-white m-auto'>1</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={`hidden lg:flex lg:items-center lg:justify-between lg:px-6 lg:border-b-2 border-blue_sky_90`}>
                                        <div className={`flex flex-col justify-center h-full items-center cursor-pointer w-fit py-[8px] px-3 border-b-2 border-primary_color_10 bg-blue_sky`}>
                                            <ViewListRounded className='text-primary_color_10' />
                                            <p className={`${"text-primary_color_10 text-base"}`}>Janaza</p>
                                        </div>
                                        <AlertDialog.Root open={openDialogFilter} onOpenChange={setOpenDialogFilter}>
                                            <AlertDialog.Trigger>
                                                <div className={`relative flex flex-row items-center gap-2 cursor-pointer rounded-lg py-[6px] w-fit h-fit px-3 border-[1.5px] border-primary_color_10 bg-blue_sky hover:bg-black hover:bg-opacity-10`} onClick={() => setSelectedMode(Mode.List)}>
                                                    <ViewListRounded className='text-primary_color_10' />
                                                    <p className={`${"text-primary_color_10 text-base"}`}>Filtres</p>
                                                    { selectedlocalisationFilter != null &&
                                                        <div className=' flex absolute top-0 right-0 h-5 w-5 rounded-full self-center bg-primary_color_10  translate-x-1/2 -translate-y-1/2'>
                                                            <p className='text-xs font-medium text-white m-auto'>1</p>
                                                        </div>
                                                    }
                                                </div>
                                            </AlertDialog.Trigger>
                                            <AlertDialog.Portal>
                                                <AlertDialog.Overlay onClick={() => setOpenDialogFilter(false)} className="bg-black bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
                                                <AlertDialog.Content aria-label='salut toi' className="data-[state=open]:animate-contentShow fixed z-[51] top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                                                    <div className={`h-fit flex flex-col gap-3 bottom-0 w-full`}>
                                                        <div
                                                            className='flex flex-row rounded-full mt-2 mb-4 gap-6 h-fit'
                                                            onClick={() => setOpenDialogFilter(false)}
                                                        >
                                                            <CloseRounded className='text-black cursor-pointer'/>
                                                            <p className='text-black font-medium text-base'>Filtres</p>
                                                        </div>
                                                        <div className="w-full gap-6">
                                                            <p className='text-black font-medium text-lg'>Localisation</p>
                                                        </div>
                                                        <div className='flex flex-wrap w-full mb-5 h-fit gap-x-3 gap-y-3'>
                                                            {
                                                                filtersLocalisation.map((localisation) => (
                                                                    <div
                                                                        key={localisation}
                                                                        className={`${tempSelectedFilter == localisation ? "border-primary_color_80 bg-primary_color_80" : "bg-white border-black"} flex flex-row px-2 py-[3px] gap-2 items-center cursor-pointer transition-colors rounded-lg border-[1.7px]`}
                                                                        onClick={() => setTempSelectedFilter(tempSelectedFilter == localisation ? null : localisation)}
                                                                    >
                                                                        { tempSelectedFilter == localisation &&
                                                                            <CheckRounded className={`text-black`}/>
                                                                        }
                                                                        <p className='font-normal text-base text-black'>{localisation}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <PlainButton
                                                            className='rounded-xl'
                                                            text='Afficher les résultats'
                                                            type='button'
                                                            onClick={onValidFilters}
                                                        />
                                                    </div>
                                                </AlertDialog.Content>
                                            </AlertDialog.Portal>
                                        </AlertDialog.Root>
                                    </div>
                                    <div className='lg:flex lg:flex-col lg:h-full'>
                                        <div className='w-full lg:flex lg:flex-row h-[calc(100%-68px)]'>
                                            <div
                                                ref={scrollRef}
                                                className={` transition-all flex flex-col items-center h-full pb-5 m-auto w-full max-w-[620px] gap-4 lg:mb-0 lg:pt-4 lg:pb-[16px] lg:auto-cols-auto lg:py-4 lg:px-6 lg:overflow-scroll lg:[&::-webkit-scrollbar]:hidden lg:[-ms-overflow-style:none] lg:[scrollbar-width:none]`}
                                            >
                                                { getDifferences(defaultPrayers, realTimePrayers).addedPrayers.length != 0 &&
                                                    <div
                                                        className='flex flex-row items-center gap-2 shadow-md fixed bg-primary_color hover:bg-primary_color_40 transition-colors cursor-pointer text-white rounded-full px-5 py-2'
                                                        onClick={setNewPrayers}
                                                    >
                                                        <ArrowUpwardRounded className='text-white cursor-pointer'/>
                                                        <p>{`${getDifferences(defaultPrayers, realTimePrayers).addedPrayers.length} Nouvelles Janaza`}</p>
                                                    </div>
                                                }
                                                
                                                { Object.keys(prayers).length === 0 &&
                                                    <div className={`flex flex-col pt-8 self-center items-center justify-centers`}>
                                                        <h2 className="text-xl text-center text-primary_color_10 font-medium mb-3">Aucune Janaza</h2>
                                                        <h3 className="text-base text-center font-medium mb-10 text-grey_light">Al Hamdulillah, aucune Janaza prévu pour le moment.</h3>
                                                    </div>
                                                }
                                                { Object.keys(prayers).length !== 0 &&
                                                    Object.entries(prayers).map(([date, prayersForDate]) => (
                                                        <div key={date} className='w-full flex flex-col gap-3 lg:gap-4'>
                                                            <p className='w-full font-medium text-xl mt-3 text-black mb-2 lg:text-3xl lg:mb-3 lg:mt-8'>{formatPrayerDate(date)}</p>
                                                            {
                                                                prayersForDate.map(prayer => (
                                                                    <PrayerCardMobile
                                                                        key={prayer.id}
                                                                        prayer={prayer}
                                                                        currentUser={currentUser}
                                                                        className="cursor-pointer rounded-xl transition-all xl:hover:shadow-md"
                                                                        onDelete={(prayerId: string) => onDeletePrayer(prayerId)}
                                                                        onPrayerViewed={onUpdateCountView}
                                                                        onMouseEnter={() => {
                                                                            enlargeMarker(prayer.id)
                                                                            setselectedPrayer(prayer)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            resetMarker(prayer.id)
                                                                            setselectedPrayer(undefined)
                                                                        }}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='hidden lg:block lg:relative lg:flex-grow lg:w-full'>
                                                <APIProvider apiKey="IzaSyB4uoN_f8_foBcvyY_pVJs3n59bxRFbJbI">
                                                    <Map
                                                        defaultZoom={6}
                                                        defaultCenter={{ lat: 47.084400, lng: 2.396400 }}
                                                        className='h-full w-full'
                                                        disableDefaultUI
                                                        reuseMaps={true}
                                                        gestureHandling={"greedy"}
                                                    >
                                                        {
                                                            Object.values(prayers).flat().map(prayer => (
                                                                <Marker
                                                                    key={prayer.id}
                                                                    ref={(ref) => {
                                                                        if (prayer.id != undefined && ref) {
                                                                            markersRef.current[prayer.id] = ref;
                                                                        }
                                                                    }}
                                                                    onMouseOver={() => {
                                                                        enlargeMarker(prayer.id)
                                                                        setselectedPrayer(prayer)
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        resetMarker(prayer.id)
                                                                        setselectedPrayer(undefined)
                                                                    }}
                                                                    icon={{url: janazaMarker, scaledSize: new window.google.maps.Size(35.4, 60)}}
                                                                    position={{lat: prayer.location.latitude, lng: prayer.location.longitude}}
                                                                />
                                                            ))
                                                            
                                                        }
                                                        { selectedPrayer?.id != undefined &&
                                                            <InfoWindow
                                                                headerDisabled={true}
                                                                className='flex flex-col bg-transparent bg-none p-0'
                                                                anchor={markersRef.current[(Object.values(prayers).flat().find((prayer) => selectedPrayer?.id == prayer.id))?.id ? selectedPrayer.id : ""]}
                                                            >
                                                                <p className='text-sm text-black font-medium'>{`${selectedPrayer.name}`}</p>
                                                                <p className='text-sm text-black font-medium'>{`Le ${selectedPrayer.date} à ${selectedPrayer.hour}`}</p>
                                                            </InfoWindow>
                                                        }
                                                    </Map>
                                                </APIProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <GoogleMap
                                    prayers={defaultPrayers}
                                    setSelectionModeVisibility={(value: boolean) => setSelectModeVisibility(value)}
                                />
                            )}
                        </>
                    )}
                    <ChoiceModeButton
                        selectedMode={selectedMode}
                        selectedModeVisibility={selectModeVisibility}
                        setSelectedMode={setSelectedMode}
                    />
                </div>
                { filterBottomSheetVisibility == true &&
                    <div className='fixed flex flex-col bg-black bg-opacity-50 justify-end z-50 h-full w-full'>
                        <div className={`h-fit flex flex-col gap-3 rounded-tl-3xl rounded-tr-3xl bottom-0 w-full transition-[300px] p-4 bg-white lg:pt-[90px] lg:rounded-tl-none lg:rounded-tr-none`}>
                            <div
                                className='flex flex-row rounded-full mt-2 mb-4 gap-6 h-fit'
                                onClick={() => onFilterBottomSheetChanged(false)}
                            >
                                <CloseRounded className='text-black'/>
                                <p className='text-black font-medium text-base'>Filtres</p>
                            </div>
                            <div className="flex flex-col w-full gap-6">
                                <p className='text-black font-medium text-lg'>Localisation</p>
                            </div>
                            <div className='flex flex-wrap w-full mb-5 h-fit gap-x-3 gap-y-3'>
                                {
                                    filtersLocalisation.map((localisation) => (
                                        <div
                                            key={localisation}
                                            className={`${tempSelectedFilter == localisation ? "border-primary_color_80 bg-primary_color_80" : "bg-white border-black"} flex flex-row px-2 py-[3px] gap-2 items-center transition-colors rounded-lg border-[1.7px]`}
                                            onClick={() => setTempSelectedFilter(tempSelectedFilter == localisation ? null : localisation)}
                                        >
                                            { tempSelectedFilter == localisation &&
                                                <CheckRounded className={`text-black`}/>
                                            }
                                            <p className='font-normal text-base text-black'>{localisation}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <PlainButton
                                className='rounded-xl'
                                text='Afficher les résultats'
                                type='button'
                                onClick={onValidFilters}
                            />
                        </div>
                    </div>
                }
            </section>
            { selectedMode === Mode.List &&
                <div ref={ref}>
                    <FooterJanaza
                        className={``}
                        onContactUs={() => undefined}
                    />
                </div>
            }
            
            { overlayVisible &&
                <div className="fixed top-0 left-0 z-50 bg-black h-screen w-full bg-opacity-50">
                    <div className="flex flex-col h-full justify-center">
                        <svg aria-hidden="true" className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 self-center fill-primary_color" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                    </div>
                </div>
            }
            <MenuOverlay
                isMenuOpen={isMenuOpen}
                currentUser={currentUser}
                closeMenu={() => setMenuOpen(false)}
                setSigningOut={handleSignOut}
                navigateSignIn={() => navigate("/signin")}
                navigateSignUp={() => navigate("/signup")}
            />
        </div>
    );
}

const ChoiceModeButton = ({selectedMode, selectedModeVisibility, setSelectedMode}: ChoiceModeButtonProps) => {
    return (
        <div className='lg:hidden'>
            {selectedMode === Mode.List ? (
                <>
                    <div className={`transition-all duration-50 ${!selectedModeVisibility ? 'opacity-0 mb-0 pointer-events-none' : 'opacity-100 mb-8'} flex flex-row items-center gap-2 fixed bottom-0 left-1/2 -translate-x-1/2  shadow-md cursor-pointer rounded-full py-[6px] w-fit px-5 bg-primary_color_10`} onClick={() => setSelectedMode(Mode.Map)}>
                        <MapRounded className='text-white' />
                        <p className={`${"text-white text-lg"}`}>Carte</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={`transition-all duration-50 ${!selectedModeVisibility ? 'opacity-0 mb-0 pointer-events-none' : 'opacity-100 mb-8'} flex flex-row items-center gap-2 fixed bottom-0 left-1/2 -translate-x-1/2 shadow-md cursor-pointer rounded-full py-[6px] w-fit px-5 bg-primary_color_10`} onClick={() => setSelectedMode(Mode.List)}>
                        <ViewListRounded className='text-white' />
                        <p className={`${"text-white text-lg"}`}>Liste</p>
                    </div>
                </>
            )}
        </div>
    )
}

const GoogleMap = ({ prayers, setSelectionModeVisibility }: GoogleMapProps) => {
    const markersRef = useRef<{ [key: string]: google.maps.Marker }>({});

    const [position, setPosition] = useState({ lat: 47.084400, lng: 2.396400 })

    const [selectedPrayer, setSelectedPrayer] = useState<PrayerModel>()

    const onCloseBottomSheet = () => {
        setSelectedPrayer(undefined)
        setSelectionModeVisibility(true)
    }

    const onSelectPrayer = (prayer: PrayerModel) => {
        setSelectionModeVisibility(false)

        setSelectedPrayer(prayer)

        setPosition({ lat: prayer.location.latitude, lng: prayer.location.longitude })
    }

    return (
        <APIProvider apiKey="IzaSyB4uoN_f8_foBcvyY_pVJs3n59bxRFbJbI">
            <div className='relative flex-grow'>
                <Map
                    defaultZoom={6}
                    defaultCenter={{ lat: selectedPrayer != undefined ? selectedPrayer.location.latitude : position.lat, lng: selectedPrayer != undefined ? selectedPrayer.location.longitude : position.lng }}
                    className='h-full w-full'
                    disableDefaultUI
                    reuseMaps={true}
                    gestureHandling={"greedy"}
                >
                    {
                        prayers.map(prayer => (
                            <Marker
                                key={prayer.id}
                                ref={(ref) => {
                                    if (prayer.id != undefined && ref) {
                                        markersRef.current[prayer.id] = ref;
                                    }
                                }}
                                icon={{url: janazaMarker, scaledSize: new window.google.maps.Size(35.4, 60)}}
                                position={{lat: prayer.location.latitude, lng: prayer.location.longitude}}
                                onClick={() => onSelectPrayer(prayer)}
                            />
                        ))
                    }
                    { selectedPrayer?.id != undefined &&
                        <InfoWindow
                            headerDisabled={true}
                            className='flex flex-col'
                            anchor={markersRef.current[(prayers.find((prayer) => selectedPrayer?.id == prayer.id))?.id ? selectedPrayer.id : ""]}
                        >
                            <p className='text-sm text-black font-medium'>{`${selectedPrayer.name}`}</p>
                            <p className='text-sm text-black font-medium'>{`Le ${selectedPrayer.date} à ${selectedPrayer.hour}`}</p>
                        </InfoWindow>
                    }
                </Map>
                { selectedPrayer != undefined &&
                    <>
                        <div className={`${selectedPrayer != undefined ? "max-h-auto lg:h-full lg:w-[420px]" : "lg:h-full lg:w-0"} flex flex-col absolute gap-3 items-end rounded-tl-3xl rounded-tr-3xl bottom-0 w-full transition-[300px] p-4 bg-white lg:pt-[90px] lg:rounded-tl-none lg:rounded-tr-none`}>
                            <button className='w-9 h-9 rounded-full bg-grey_30' onClick={() => onCloseBottomSheet()}>
                                <CloseRounded className='text-black'/>
                            </button>
                            <div className="flex flex-col w-full gap-2">
                                <div>
                                    <p className="text-lg font-medium text-primary_color_10">{`Salat janaza de ${selectedPrayer.name.charAt(0).toUpperCase() + selectedPrayer.name.slice(1)}`}</p>
                                </div>
                                <div className='flex flex-col p-3 gap-1 rounded-md bg-grey_30'>
                                    <div className='flex flex-row justify-between'>
                                        <p className="text-sm text-black">Date :</p>
                                        <p className="text-sm text-black">{selectedPrayer.date}</p>
                                    </div>
                                    <div className='flex flex-row justify-between'>
                                        <p className="text-sm text-black">Heure :</p>
                                        <p className="text-sm text-black">{`${selectedPrayer.prayName === "" ? `${selectedPrayer.hour}` : `${selectedPrayer.hour} (après ${selectedPrayer.prayName})`}`}</p>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2 self-start ">
                                    <ChipAction text="Partager" onClick={() => setSelectedPrayer(undefined)} className="hover:shadow-md transition-shadow"
                                        icon={<LinkRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>}
                                    />
                                    <ChipAction text="Enregistrer" onClick={() => setSelectedPrayer(undefined)} className="hover:shadow-md transition-shadow"
                                        icon={<ImageRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>}
                                    />
                                </div>
                                <div className='flex flex-row p-3 gap-1 rounded-md bg-grey_30'>
                                    <WcRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                    <p className="text-sm self-center font-light text-grey_primary_color_10">{selectedPrayer.sexe === "Je ne sais pas" ? "Aucune infos sur le sexe" : selectedPrayer.sexe }</p>
                                </div>
                                <div className='flex flex-row p-3 gap-1 rounded-md bg-grey_30'>
                                    <LocationOnRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                    <p className="text-sm font-light text-grey_primary_color_10 whitespace-pre-line">{
                                        `${selectedPrayer.address}`
                                    }</p>
                                </div>
                                <div className='flex flex-row p-3 gap-1 rounded-md bg-grey_30 items-center'>
                                    <DirectionsCarRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                    <div className="flex flex-col h-fit">
                                        <p className={`text-sm ${selectedPrayer.convoi === "Oui" ? "text-gold" : "text-primary_color_10"} font-light`}>{
                                            `${selectedPrayer.convoi === "Je ne sais pas" ? "Aucune information sur l'enterrement." : selectedPrayer.convoi}`
                                        }</p>
                                        { selectedPrayer.convoi === "Oui" &&
                                            <p className="text-sm font-light text-grey_primary_color_10">{`${selectedPrayer.addressConvoi} ${selectedPrayer.hourConvoy === undefined ? "" : `à ${selectedPrayer.hourConvoy}`}`}</p>
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-row p-3 gap-1 rounded-md bg-grey_30 border-gold border-[1px] items-center'>
                                    <p className="text-sm self-center font-light text-grey_primary_color_10 whitespace-pre-line">{
                                        `${selectedPrayer.moreInfos === "" ? "" : selectedPrayer.moreInfos+ "\n\n"}Qu'Allah lui pardonne et lui fasse miséricorde.\nInna lillah wa inna ilayhi raji'un.\nCourage à la famille et à ses proches`
                                    }</p>
                                </div>
                                <div className="flex flex-row self-end items-center">
                                    <EqualizerRounded className="text-grey_light self-center text-lg" fontSize="small"/>
                                    <p className="text-grey_light text-sm font-normal self-center">{selectedPrayer.view_count}</p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </APIProvider>
    )
}

const groupPrayersByDate = (prayers: PrayerModel[]) => {
    return prayers.reduce((acc, prayer) => {
      const date = extractDateInfo(prayer.begin_date);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(prayer);
      return acc;
    }, {} as Record<string, PrayerModel[]>);
};

const getDifferences = (oldPrayers: PrayerModel[], newPrayers: PrayerModel[]) => {
    // Convertir les listes en Map pour un accès plus rapide par ID
    const oldPrayersMap = oldPrayers.map(prayer => prayer);
    const newPrayersMap = newPrayers.map(prayer => prayer);

    // Obtenir les nouvelles prières (celles dans newPrayers mais pas dans oldPrayers)
    const addedPrayers = newPrayers.filter(prayer => !oldPrayersMap.some(oldPrayer => oldPrayer.id === prayer.id));

    // Obtenir les prières supprimées (celles dans oldPrayers mais pas dans newPrayers)
    const removedPrayers = oldPrayers.filter(prayer => !newPrayersMap.some(newPrayer => newPrayer.id === prayer.id));

    return { addedPrayers, removedPrayers };
};

export default Prayers;