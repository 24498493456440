function formatPrayerDate(prayerDate: string): string {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const prayerDateObj = new Date(prayerDate);

    // Comparer si la prière est aujourd'hui
    if (
        prayerDateObj.getDate() === today.getDate() &&
        prayerDateObj.getMonth() === today.getMonth() &&
        prayerDateObj.getFullYear() === today.getFullYear()
    ) {
        return "Aujourd'hui";
    }

    // Comparer si la prière est demain
    if (
        prayerDateObj.getDate() === tomorrow.getDate() &&
        prayerDateObj.getMonth() === tomorrow.getMonth() &&
        prayerDateObj.getFullYear() === tomorrow.getFullYear()
    ) {
        return "Demain";
    }

    // Sinon, retourner la date formatée "12 Novembre 2024"
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "long",
        year: "numeric",
    };
    return prayerDateObj.toLocaleDateString("fr-FR", options);
}

export default formatPrayerDate;