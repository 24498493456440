import { initializeApp } from "firebase/app";
import {
    getAuth,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    NextOrObserver,
    User,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail
} from "firebase/auth";
import { firebaseConfig } from "../../config/config";
import { User as JanazaUser } from "../../models/User"
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);

const firestore = getFirestore(app);

const userPath = "user";

export const signInUser = async (
    email: string, 
    password: string
) => {
    if (!email && !password) return;

    const signIn =  await signInWithEmailAndPassword(auth, email, password);

    if (!signIn) return;

    const user = signIn.user;
    const userExist = await isUserExist(user.uid);

    if (userExist)
        return

    addUser({
        id: user.uid,
        organisation: "Particulier",
        email: email,
        willId: null,
        commercial: false,
        updatedAt: null,
        createdAt: new Date().toISOString()
    }, true)

    return signIn
}
  
export const signUpUser = async (
    category: string,
    email: string, 
    password: string
) => {
    if (!email && !password) return;

    const createUser = await createUserWithEmailAndPassword(auth, email, password)

    if (createUser) {
        const user = createUser.user

        addUser({
            id: user.uid,
            organisation: category,
            email: email,
            willId: null,
            commercial: false,
            updatedAt: null,
            createdAt: new Date().toISOString()
        }, false)
    }

    return createUser
}

export const forgotPassword = async (
    email: string
) => {
    if (!email) return;
    return await sendPasswordResetEmail(auth, email)
}

export const userStateListener = (callback: NextOrObserver<User>) => {
    onAuthStateChanged(auth, callback)
}

export const signOutUser = async () => {
    await signOut(auth);
    console.log("User signed out");
};

export const addUser = async (
    user: JanazaUser,
    merge: boolean
) => {
    return await setDoc(doc(firestore, userPath, user.id), {
        id: user.id,
        organisation: user.organisation,
        email: user.email,
        willId: user.willId,
        commercial: user.commercial,
        updatedAt: user.updatedAt,
        createdAt: user.createdAt
    }, {merge : merge});
}

export const getUser = async(userId: string) => {
    const userData = await getDoc(doc(firestore, "user", userId))

    if (!userData)
        return

    return userData
}

const isUserExist = async (userId: string) => {
    const user = await getDoc(doc(firestore, "user", userId))

    if (!user)
        return

    if (user.exists())
        return true
    else
        return false
}