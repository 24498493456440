/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

interface PlainButtonProps {
    text: string,
    type?: "submit" | "reset" | "button" | undefined
    className?: string,
    isDisabled?: boolean,
    onClick?: () => any,
}

const PlainButton = ({ text, type, className, isDisabled, onClick }: PlainButtonProps) => {
    return (
        <button type={type} onClick={onClick} disabled={isDisabled} className={`${className} py-0.5 rounded-full bg-primary_color h-auto text-white hover:bg-opacity-90 transition-colors`}>
            <p className="font-medium text-base px-4 py-3 lg:text-base">{text}</p>
        </button>
    )
}

export default PlainButton;