import React from "react";

interface HeadTitleProps {
    text: string,
    className?: string
}

const HeadTitle = ({ text, className }: HeadTitleProps) => {
    return (
        <h1 className={`${className} text-primary_color_10 font-semibold text-[3rem] leading-[3.15rem] lg:text-6xl px-2 pb-3 text-center`}>{text}</h1>
    )
}

export default HeadTitle;