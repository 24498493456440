import React from "react"

interface MenuHamburgerProps {
    isClicked: boolean
    className: string,
    onClick: () => any
}

const HamburgerMenu = ({isClicked, className, onClick}:MenuHamburgerProps) => {
    return (
        <div className={`${className} h-[48px] items-center flex`}> {/* Navigation Menu */}
            <div className={`w-fit space-y-2 lg:hidden`} onClick={onClick}>
                <span className={`${isClicked ? "rotate-45 translate-y-2" : ""} transition ease transform duration-300 block w-8 h-0.5 bg-primary_color_10`}></span>
                <span className={`${isClicked ? "opacity-0" : "opacity-100"} transition ease transform duration-300 block w-8 h-0.5 bg-primary_color_10`}></span>
                <span className={`${isClicked ? "-rotate-45 -translate-y-3" : ""} transition ease transform duration-300 block w-8 h-0.5 bg-primary_color_10`}></span>
            </div>
        </div>
    )
}

export default HamburgerMenu;