import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { signUpUser } from "../../data/repository/firebaseAuth";

import janazaInline from "../../styles/drawable/janaza_inline.png";
import janazaLogo from "../../styles/drawable/janaza_logo.png";

import SecondaryTitle from "../../components/titles/SecondaryTitle";
import { LoadingOverlay } from "../../components/commons/LoadingOverlay";

import { ArrowBackIosRounded } from "@mui/icons-material";
import { toast } from "sonner";
import BorderButton from "../../components/buttons/BorderButton";
import PlainButton from "../../components/buttons/PlainButton";
import FooterJanaza from "../../components/commons/FooterJanaza";
import TwoHeaderMenu from "../../components/navigation/headerMenu/TwoHeaderMenu";
import MenuDropDown from "../../components/dropDown/MenuDropDown";


const defaultFormFields = {
    category: '',
    email: '',
    password: '',
    confirmPassword: '',
}

const SignUp: React.FunctionComponent = (props) => {
    const organisations = () => {return ["Particulier", "Mosquée", "École", "Pompe funèbre", "Laveuse", "Laveur", "Association", "Entreprise", "Autre"];};

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields)
    const { category, email, password, confirmPassword } = formFields

    const [formFieldsError, setFormFieldsError] = useState(defaultFormFields)

    const [showOrganisationDropDown, setShowOrganisationDropDown] = useState(false);
    
    const [authing, setAuthing] = useState(false);

    if (currentUser) {
        return <Navigate to="/" />;
    }

    const resetFormFields = () => {
        return (
            setFormFields(defaultFormFields)
        );
    }

    const resetFormFieldsErrors = () => {
        return (
            setFormFieldsError(defaultFormFields)
        );
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        resetFormFieldsErrors()

        if (category === "") {
            setFormFieldsError({
                ...formFieldsError,
                category: "Vous devez entrer une organisation",
            })
            setAuthing(false)
            return
        }

        if (password !== confirmPassword) {
            setFormFieldsError({
                ...formFieldsError,
                confirmPassword: "Les mots de passe doivent correspondre."
            })
            setAuthing(false)
            toast.error("Les mots de passe doivent correspondre.")
            return
        }

        try {
            setAuthing(true)

            const credentials = await signUpUser(category, email, password)

            if (credentials) {
                await timeout(1500)
                setAuthing(false)
                toast.success("Inscription réussie, Al Hamdulilah")
                resetFormFields()
                navigate("/")
            }
        } catch (error) {
            setAuthing(false)
            toast.error("Inscription impossible, veuillez réessayez")
            console.log("User sign up failed", error)
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormFields({...formFields, [name]: value })
      }

    return (
        <div className="bg-white w-full h-screen font-roboto 2xl:m-auto relative">
            <section className="flex flex-col lg:h-screen w-full overflow-hidden items-center bg-blue_sky"> {/* First Section */}
                <TwoHeaderMenu
                    classNames=""
                    currentUser={currentUser}
                    isSignInButtonVisible={true}
                />
                <div className="flex flex-col h-screen pt-32 w-11/12 lg:p-0 lg:justify-center lg:w-full lg:max-w-sm">
                    <SecondaryTitle text="S'inscrire" className="mb-1"></SecondaryTitle>
                    <h3 className="text-grey_light font-regular text-base lg:text-base mb-6">{`En vous inscrivant, vous pourrez ajouter des Janaza ainsi que les modifier.`}</h3>
                    <form onSubmit={handleSubmit}>
                        <MenuDropDown
                            className=""
                            placeholder="Qui êtes-vous ?"
                            isDropDownClicked={showOrganisationDropDown}
                            valueList={organisations()}
                            bgColor="bg-white"
                            borderColor="border-white"
                            query={category}
                            setShowDropDown={(value: boolean) => setShowOrganisationDropDown(value)}
                            valueSelection={(value: string) => setFormFields({...formFields, category: value })}
                        />
                        <p className="font-regular text-sm text-red mb-2">{formFieldsError.category}</p>

                        <input
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            className={`${email != "" ? "border-primary_color" : "border-white"} bg-white text-base w-full mb-0.5 py-3 box-border placeholder:text-grey_light outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                        />
                        <p className="font-regular text-sm text-red mb-2">{formFieldsError.email}</p>
                        
                        <input
                            required
                            placeholder="Mot de passe"
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            className={`${password != "" ? "border-primary_color" : "border-white"} bg-white text-base w-full mb-0.5 py-3 box-border placeholder:text-grey_light outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                        />
                        <p className="font-regular text-sm text-red mb-2">{formFieldsError.password}</p>
                        
                        <input
                            required
                            placeholder="Confirmer votre mot de passe"
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            className={`${confirmPassword != "" ? "border-primary_color" : "border-white"} bg-white text-base w-full mb-0.5 py-3 box-border placeholder:text-grey_light outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                        />
                        <p className="font-regular text-sm text-red mb-2">{formFieldsError.confirmPassword}</p>
                        
                        <PlainButton
                            type="submit"
                            text="Continuer"
                            className="w-full mt-2"
                        />
                    </form>
                    <p className="text-xs my-4 text-start text-black text-opacity-60">
                        {`En m'inscrivant, j'accepte les `}
                        <a
                            href="https://pages.flycricket.io/janaza/privacy.html"
                            className=""
                        >
                            <span className="underline">
                                règles de confidentialités
                            </span>
                        </a>
                    </p>
                </div>
            </section>
            <FooterJanaza className={``} onContactUs={() => undefined}/>
            { authing &&
                <LoadingOverlay className=""/>
            }
        </div>
    );
}

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

export default SignUp;